import React from "react";

const Footer = () => {
  return (
    <footer
      className="bg-[#1b2e4b] px-4 flex justify-between items-center h-[75px]"
      style={{
        position: "absolute",
        bottom: "0",
        left: 0,
        right: 0,
        zIndex: 999,
      }}
    >
      <div className="flex-1">
        <a
          href="https://pedi.ph"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          <img
            src="/assets/pedi-word-logo.svg"
            alt="Pedi Logo"
            className="h-[31px]"
          />
        </a>
      </div>
      <div className="flex">
        <a
          href="https://pdcb.link/blEEFw"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          <img
            src="/assets/apple-app-store.svg"
            alt="Apple App Store"
            className="h-[30px] mr-2"
          />
        </a>
        <a
          href="https://pdcb.link/A0rZjv"
          target="_blank"
          rel="noreferrer"
          className="block"
        >
          <img
            src="/assets/google-play-store.svg"
            alt="Google Play Store"
            className="h-[30px]"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
