import React from "react";
import Footer from "../../../components/Footer";

const ExpiredLinkPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 bg-white flex items-center justify-center">
        <div className="text-center">
          <img
            src="/assets/map-image.svg"
            alt="Map Placeholder"
            className="w-[100] h-[100] mx-auto"
          />
          <p className="font-semibold text-lg mt-6 text-[#313131]">
            Tracking link expired
          </p>
          <p className="font-normal text-base mt-3 text-[#313131]">
            You may check with your friend
            <br />
            on the trip status.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExpiredLinkPage;
