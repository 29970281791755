import React from "react";

const Loader = () => {
  return (
    <div className="absolute bottom-28 left-8 rounded-[20px] bg-white h-72 w-96 animate-pulse">
      <div className="border-b border-[#A7A9B7] py-5 mx-5">
        <div className="h-6 bg-gray-300 rounded"></div>
      </div>
      <div className="flex justify-between p-5 border-b-[10px] border-[#F4F4F4]">
        <div>
          <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
          <div className="flex items-center mt-2">
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/4 ml-2"></div>
            <div className="flex items-center ml-2">
              <div className="h-4 w-4 bg-gray-300 rounded mr-1"></div>
              <div className="h-4 bg-gray-300 rounded w-16"></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="h-16 w-16 rounded-full bg-gray-300"></div>
          <div className="w-6 h-6 absolute top-0 right-0 bg-gray-300 rounded"></div>
        </div>
      </div>
      <div className="p-[20px]">
        <div className="flex items-center justify-between">
          <div className="h-4 w-4 bg-gray-300 rounded mr-1"></div>
          <div className="h-4 bg-gray-300 rounded w-full"></div>
        </div>
        <div className="flex items-center justify-between mt-[20px]">
          <div className="h-4 w-4 bg-gray-300 rounded mr-1"></div>
          <div className="h-4 bg-gray-300 rounded w-full"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
