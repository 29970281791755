import React from "react";
import Footer from "../../../components/Footer";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 bg-white flex items-center justify-center">
        <div className="text-center">
          <img
            src="/assets/pedi-icon.svg"
            alt="Pedi Icon Light"
            className="w-[100] h-[82] mx-auto"
          />
          <p className="text-[#313131] font-semibold text-lg mt-6 ">
            Sorry, the page you were looking
            <br />
            for doesn’t exist
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
