import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import "./index.css";
import Loader from "./Loader";

const MobileView = (props) => {
  const { bookingDetails, statusText } = props;

  return (
    <BottomSheet
      open={true}
      defaultSnap={({ maxHeight }) => 280}
      snapPoints={({ maxHeight }) =>
        maxHeight ? [280, maxHeight * 0.5, 400] : [0, 0, 0]
      }
      expandOnContentDrag={true}
      blocking={false}
    >
      {!bookingDetails && <Loader />}
      <div className="border-b border-[#C6CAD0] py-5 mx-5">
        {statusText ? (
          <p className="font-semibold text-[22px] text-[#313131]">
            {statusText}
          </p>
        ) : (
          <div className="flex space-x-2">
            <div className="h-3 w-3 bg-gray-300 rounded-full animate-[bounce_1s_infinite]"></div>
            <div className="h-3 w-3 bg-gray-300 rounded-full animate-[bounce_1s_infinite_300ms]"></div>
            <div className="h-3 w-3 bg-gray-300 rounded-full animate-[bounce_1s_infinite_500ms]"></div>
          </div>
        )}
      </div>
      <div className="flex justify-between p-5 border-b-[10px] border-[#F4F4F4]">
        {bookingDetails?.relationships?.driver && (
          <>
            <div>
              <p className="font-semibold text-lg text-[#313131]">
                {bookingDetails?.relationships?.driver?.full_name}
              </p>
              <div className="flex items-center mt-2">
                <div className="font-normal text-sm text-[#575757]">
                  {
                    bookingDetails?.relationships?.driver?.driver_vehicle
                      ?.attributes?.brand
                  }
                  ,
                  {
                    bookingDetails?.relationships?.driver?.driver_vehicle
                      ?.attributes?.color
                  }
                </div>
                <div
                  className="font-semibold text-lg text-white ml-2 px-3 py-1 rounded-lg"
                  style={{
                    backgroundColor: "#313131",
                  }}
                >
                  {
                    bookingDetails?.relationships?.driver?.driver_vehicle
                      ?.attributes?.body_number
                  }
                </div>
                <div className="flex items-center ml-2">
                  <img
                    src="/assets/star.svg"
                    alt="Star Rating"
                    className="w-5 h-5"
                  />
                  <p className="font-normal text-xs text-gray-900 ml-1">
                    {bookingDetails?.relationships?.driver?.rating}
                  </p>
                </div>
              </div>
            </div>

            <div className="relative">
              <img
                src={
                  bookingDetails?.relationships?.driver?.bio_detail?.photo?.url
                }
                alt="Driver Profile"
                className="w-[70px] h-[70px] rounded-full border-4 border-[#3B82F4]"
              />
              <img
                src="/assets/driver-badge.svg"
                alt="Driver Badge"
                className="w-6 h-6 absolute top-0 right-0"
              />
            </div>
          </>
        )}
      </div>
      <div className="p-[20px]">
        <div className="flex items-center ">
          <img
            src="/assets/pickup.svg"
            alt="Driver Badge"
            className="w-4 h-4"
          />
          <p className="ml-2 truncate ">
            {bookingDetails?.attributes?.origin?.address}
          </p>
        </div>
        <div className="flex items-center  mt-[20px]">
          <img
            src="/assets/dropoff.svg"
            alt="Driver Badge"
            className="w-4 h-4"
          />
          <p className="ml-2 truncate">
            {bookingDetails?.attributes?.destination?.address}
          </p>
        </div>
      </div>
    </BottomSheet>
  );
};

export default MobileView;
