import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../lib/axios";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import GoogleMapReact from "google-map-react";
import BookingCard from "../../../components/BookingCard";
import mapStyle from "./mapStyle";
import MapMarker from "../../../components/MapMarker";
import { getMidPoint } from "../../../utils/getMidpoint";
import { doc, onSnapshot } from "firebase/firestore";
import db from "../../../lib/firebase";

const BookingDetails = () => {
  const defaultProps = {
    center: {
      lat: 9.654874,
      lng: 123.864124,
    },
    zoom: 16,
  };
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const mapRef = useRef(null);

  const [bookingDetails, setBookingDetails] = useState(null);
  const [driverLocation, setDriverLocation] = useState(null);

  const fetchBookingDetails = useCallback(async () => {
    try {
      await axios
        .get(
          `safety/v1/booking-details/${bookingId}?api_key=${process.env.REACT_APP_PEDI_API_KEY}`
        )
        .then((res) => {
          const booking = res.data.data;
          setBookingDetails(booking);
          const midPoint = getMidPoint(
            booking?.attributes?.origin?.latitude,
            booking?.attributes?.origin?.longitude,
            booking?.attributes?.destination?.latitude,
            booking?.attributes?.destination?.longitude
          );

          mapRef.current.panTo(midPoint);
        });
    } catch (error) {
      console.log(error.response);
      navigate("/expired-link");
    }
  }, [bookingId, navigate]);

  useEffect(() => {
    fetchBookingDetails();
  }, [fetchBookingDetails]);

  useEffect(() => {
    if (mapRef.current && bookingDetails) {
      const waypoints = bookingDetails?.attributes?.waypoints?.map((point) => ({
        lat: point.latitude,
        lng: point.longitude,
      }));
      const polyline = new window.google.maps.Polyline({
        path: waypoints,
        geodesic: true,
        strokeColor: "#DD1600",
        strokeOpacity: 0.7,
        strokeWeight: 5,
      });

      polyline.setMap(mapRef.current);

      const bounds = new window.google.maps.LatLngBounds();
      waypoints.forEach((point) => bounds.extend(point));
      mapRef.current.fitBounds(bounds, 20);
    }
  }, [bookingDetails]);

  useEffect(() => {
    if (bookingDetails && bookingDetails?.relationships?.driver) {
      const unsubscribe = onSnapshot(
        doc(db, "PediDrivers", bookingDetails?.relationships?.driver?.id),
        (doc) => {
          if (doc.data() && doc.data().location) {
            const location = doc.data().location;
            const status = bookingDetails.attributes.status.toLowerCase();

            if (
              //   status !== "completed" ||
              status !== "scheduled" ||
              status !== "cancelled"
            ) {
              setDriverLocation(location);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
      return unsubscribe;
    }
  }, [bookingDetails]);

  return (
    <>
      <div className="flex flex-col min-h-screen ">
        <div
          className="h-screen w-screen"
          style={{
            height: "calc(100vh - 75px)",
          }}
        >
          <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={{
              styles: mapStyle,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map;
            }}
          >
            {bookingDetails?.attributes?.origin && (
              <MapMarker
                lat={bookingDetails?.attributes?.origin?.latitude}
                lng={bookingDetails?.attributes?.origin?.longitude}
                imgUrl="/assets/pickup-marker.svg"
                style={{
                  height: 42,
                  width: 42,
                  transform: `translate(-50%, -50%)`,
                }}
              />
            )}

            {bookingDetails?.attributes?.destination && (
              <MapMarker
                lat={bookingDetails?.attributes?.destination?.latitude}
                lng={bookingDetails?.attributes?.destination?.longitude}
                imgUrl="/assets/dropoff-marker.svg"
                style={{
                  height: 42,
                  width: 42,
                  transform: `translate(-50%, -50%)`,
                }}
              />
            )}
            {driverLocation && (
              <MapMarker
                lat={driverLocation.latitude}
                lng={driverLocation.longitude}
                imgUrl="/assets/tricycle-marker.svg"
                style={{
                  height: 42,
                  width: 42,
                  transform: `translate(-50%, -50%) rotate(${driverLocation.heading}deg)`,
                }}
              />
            )}
          </GoogleMapReact>
          <BookingCard bookingDetails={bookingDetails} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BookingDetails;
