import React from "react";

const MapMarker = (props) => {
  const { lat, lng, imgUrl, ...rest } = props;

  return (
    <div lat={lat} lng={lng}>
      <img src={imgUrl} alt="Map Marker" {...rest} />
    </div>
  );
};

export default MapMarker;
