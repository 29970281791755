import React, { useState, useEffect } from "react";
import MobileView from "./MobileView";
import WebView from "./WebView";
import { doc, onSnapshot } from "firebase/firestore";
import db from "../../lib/firebase";

const BookingCard = (props) => {
  const { bookingDetails } = props;
  const isMobile = window.innerWidth <= 768;
  const [booking, setBooking] = useState(bookingDetails);
  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    if (bookingDetails) {
      const docId = `${bookingDetails?.attributes?.id}-${bookingDetails?.attributes?.created?.string}`;
      const unsubscribe = onSnapshot(
        doc(db, "AppBookings", docId),
        (doc) => {
          const booking = doc.data();
          setBooking(booking.data);

          const status = booking.data.attributes.status.toLowerCase();

          switch (status) {
            case "waiting":
              setStatusText("Waiting for driver");
              break;
            case "cancelled":
              setStatusText("Booking cancelled");
              break;
            case "booked":
              setStatusText("Driver is on the way");
              break;
            case "driver-arrived":
              setStatusText("Driver has arrived");
              break;
            case "pickup":
              setStatusText("Going to destination");
              break;
            case "scheduled":
              setStatusText("Waiting for pickup time");
              break;
            case "completed":
              setStatusText("Booking completed");
              break;

            default:
              setStatusText("");
              break;
          }
        },
        (error) => {
          console.log(error);
        }
      );
      return unsubscribe;
    }
  }, [bookingDetails]);

  if (isMobile) {
    return <MobileView bookingDetails={booking} statusText={statusText} />;
  }
  return <WebView bookingDetails={booking} statusText={statusText} />;
};

export default BookingCard;
